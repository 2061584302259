import React from 'react';
import Header from './Header';
import Hero from './Hero';
import FeatureSection from './FeatureSection';
import IncludedFeatures from './IncludedFeature';
import Footer from './Footer';

const decorativeImage = "/assets/decorative-image-cropped.png"

const MainContent: React.FC = () => {
  return (
    <div className="flex flex-col bg-white grow-0">
      <Header logoSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/696d4acd2b107e3b06daec82ade9b7268850ee77c20ccef5b487075a5fab3a97?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" />
      <Hero imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/edca0fbe7a7fa495b52fa35a662bb97bf783ead4845be168a204f66fdbc35608?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" starsSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/601443d86233a5d2427c65681fd45c9f0a7297f05feb5569fd240957b8f46862?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" />
      <img loading="lazy" src={decorativeImage} alt="Decorative image" className="object-contain w-full rounded-none aspect-[2.79] max-md:flex max-md:max-w-full max-sm:hidden" />
      <section className="flex flex-col items-center px-20 mt-32 mb-32 max-w-full max-md:px-5 max-md:pt-24">
        <div className="flex flex-col justify-center items-center px-44 py-5 w-full max-w-[1034px] max-md:px-5 max-md:max-w-full">
          <h2 className="text-6xl font-semibold tracking-tighter text-center text-black leading-[72px] max-md:max-w-full max-md:text-4xl">Scale deal flow</h2>
          <p className="mt-4 text-base font-medium leading-6 text-center text-black max-md:max-w-full">
            Venture capital investing is not for the faint of heart. With more investors breaking out into their own space, hoping to land the next unicorn company, the stakes have never been higher.
            <br /><br />
            Venture capital financing companies must stay relevant to stay in the game.
            <br />
            Don't leave an entire pool of future partners and startups untapped – invest in the best lead generation for venture capital firms and show your target audience what makes you different and trustworthy.
          </p>
          <a href="https://calendly.com/growthpartnerhq/15min" target="_blank" rel="noopener noreferrer" className="flex gap-2 justify-center items-center px-5 py-2.5 mt-8 bg-black rounded">
            <span className="self-stretch my-auto text-base font-semibold tracking-tight leading-6 text-center text-white">Explore</span>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f374869efc2f7e7b356d2e2f4cfe4f42da2b011f65fbf102d020f3892b6c5b4?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" alt="" className="object-contain shrink-0 w-6 aspect-square" />
          </a>
        </div>
      </section>
      <section className="flex flex-col items-center px-72 mt-32 mb-32 w-full max-md:px-5 max-md:py-24 max-md:max-w-full">
        <h2 className="pb-px max-w-full text-5xl font-medium leading-none text-center text-neutral-800 w-[603px] max-md:max-w-full max-md:text-4xl">
          <span className="text-neutral-800">Introducing </span>
          <span className="text-blue-500">GrowthPartner</span>
        </h2>
        <p className="mt-16 max-w-full text-3xl leading-9 text-center text-zinc-800 w-[685px] max-md:mt-10 max-md:max-w-full">
          GrowthPartner is a Venture Capital/Private Equity lead flow agency.
          <br />
          We specialize in hyper-targeted demand generation
          <br />
          to generate you leads from companies and startups for your portfolio
        </p>
        <p className="pl-9 mt-16 max-w-full text-3xl leading-9 text-center text-zinc-800 w-[633px] max-md:pl-5 max-md:mt-10 max-md:max-w-full">
          - We are passionate about what we do and
          <br />
          excited to support your ongoing growth
        </p>
      </section>
      <FeatureSection />
      <IncludedFeatures />

      <section className="flex flex-col items-center mt-32 mb-32 w-full  max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col">
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/95a2082fcf14360786ca9b0c707afa5803697b02c38fbf21fd6154218589a989?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" 
            alt="Decorative image" 
            className="object-contain w-full max-w-[800px] h-auto aspect-[2.02] max-md:aspect-auto"
          />
        </div>
        <h2 className="mt-14 text-5xl text-center capitalize leading-[57.6px] text-neutral-800 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
          Want to Talk about it?
        </h2>
        <p className="mt-14 text-lg leading-7 text-center text-neutral-800 max-md:mt-10 max-md:max-w-full">
          Our specialists are available to answer your questions 💬
        </p>
        <a href="https://calendly.com/growthpartnerhq/15min" target="_blank" rel="noopener noreferrer" className="flex overflow-hidden gap-2.5 justify-center items-center px-8 pt-4 pb-3 mt-14 text-xl leading-6 text-white bg-blue-500 shadow-sm max-w-[234px] rounded-[50px] w-[233px] max-md:px-5 max-md:mt-10">
          <span className="self-stretch my-auto underline">Start Your Chat</span>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/747dc34818cc7edb7c9fada6b9f3ce6648534ddb56ca015182b487c43e0a274a?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" alt="" className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" />
        </a>
      </section>
      <Footer />
    </div>
  );
};

export default MainContent;
