import React from 'react';
import Button from './Button';

interface HeroProps {
  imageSrc: string;
  starsSrc: string;
}

const Hero: React.FC<HeroProps> = ({ imageSrc, starsSrc }) => {
  return (
    <section className="flex overflow-hidden flex-col pb-20 w-full max-md:max-w-full">
      <div className="flex overflow-hidden flex-col w-full bg-neutral-100 max-md:max-w-full">
        <div className="flex flex-wrap justify-center items-start w-full max-md:max-w-full">
          <img loading="lazy" src={imageSrc} alt="Hero illustration" className="shrink-0 gap-2.5 aspect-[1.28] min-w-[240px] w-[367px] max-sm:mr-auto" />
          <div className="flex overflow-hidden flex-col flex-1 shrink px-8 py-16 basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
              <h1 className="text-7xl font-medium tracking-tighter leading-[86px] max-w-[897px] text-neutral-800 w-[897px] max-md:max-w-full max-md:text-4xl max-md:leading-[55px]">
                <span className="text-blue-500">Venture Capital</span> <br />
                <span className="text-neutral-800">Get </span> <span className="text-blue-500">Startup</span> <br />
                <span className="text-blue-500">Leads</span> <span className="text-neutral-800"> on Demand.</span>
              </h1>
              <div className="flex flex-col mt-11 w-full max-md:mt-10 max-md:max-w-full">
                <p className="text-xl font-medium leading-8 text-neutral-800 max-md:max-w-full">
                  Trusted by VCs
                </p>
                <div className="flex overflow-hidden flex-col mt-3 max-w-full w-[152px]">
                  <div className="flex overflow-hidden flex-col justify-center items-center w-full min-h-[24px]">
                    <img loading="lazy" src={starsSrc} alt="Rating stars" className="object-contain w-full aspect-[6.33]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-8 mr-auto w-full leading-8 text-right max-md:max-w-full">
              <p className="mr-auto text-xl font-medium text-neutral-800 max-md:max-w-full">
                Streamline Your Deal Flow:
              </p>
              <p className="mt-4 mr-auto text-xl text-left text-neutral-600 max-md:max-w-full max-sm:mr-auto">
                Leverage our lead flow systems to connect with{" "}
                <span className="text-neutral-600">startup that match your thesis</span>.
              </p>
              <Button link="https://calendly.com/growthpartnerhq/15min" text="Book a Call Now" icon="https://cdn.builder.io/api/v1/image/assets/TEMP/ed8b1def367fc175637329910bebd8b13eafb81cc7a6775728c166749ed3b044?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" />
            </div>
          </div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/080c3fd83cefc5e4484067b0a5ee3a4c70404016ddf0a539d1315dd92accbeca?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" alt="" className="object-contain shrink-0 aspect-[0.88] min-w-[240px] w-[287px] max-md:flex max-sm:hidden max-sm:ml-auto" />
        </div>
      </div>
    </section>
  );
};

export default Hero;