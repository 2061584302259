import React from 'react';

interface FeatureItemProps {
  icon: string;
  text: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon, text }) => {
  return (
    <div className="flex flex-col grow shrink justify-center text-xl min-w-[240px] w-[287px]">
      <div className="flex relative flex-1 gap-2.5 items-center py-2 size-full">
        <img loading="lazy" src={icon} alt="" className="object-contain z-0 shrink-0 self-stretch my-auto w-6 aspect-square" />
        <div className="flex absolute bottom-0 left-0 z-0 shrink-0 justify-center self-start pb-1.5 h-[30px] min-w-[240px] w-[358px]" />
        <div className="z-0 self-stretch my-auto h-[30px] w-[324px]">
          {text}
        </div>
      </div>
    </div>
  );
};

export default FeatureItem;