import React from 'react';
import Button from './Button';

interface HeaderProps {
  logoSrc: string;
}

const Header: React.FC<HeaderProps> = ({ logoSrc }) => {
  return (
    <header className="flex flex-col justify-end w-full bg-white grow-0 max-md:max-w-full">
      <div className="flex flex-col grow-0 max-md:max-w-full">
        <div className="flex flex-col justify-center items-center px-16 py-4 w-full bg-white max-md:px-5 max-md:max-w-full">
          <div className="flex flex-wrap gap-5 justify-between w-full max-w-[1344px] max-md:max-w-full">
            <div className="flex flex-col items-start self-start mt-2">
              <img loading="lazy" src={logoSrc} alt="Company Logo" className="object-contain w-40 max-w-full aspect-[5.92]" />
            </div>
            <Button link="https://calendly.com/growthpartnerhq/15min" text="Book a Call" icon="https://cdn.builder.io/api/v1/image/assets/TEMP/d7850ae7355a9135a4fe9853abb67e21a5fa32a141f77809cf8a022171dc1daf?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;