import React from 'react';

interface FeatureProps {
  title: string;
  description: string;
  iconSrc: string;
}

const Feature: React.FC<FeatureProps> = ({ title, description, iconSrc }) => {
  return (
    <div className="flex flex-col justify-center items-center self-stretch px-16 py-8 my-auto rounded-xl border border-solid bg-neutral-100 border-neutral-600 min-h-[480px] min-w-[240px] max-w-[420px] max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col justify-center items-center p-6 max-w-full bg-white rounded-2xl w-[101px] max-md:px-5">
        <img loading="lazy" src={iconSrc} alt="" className="object-contain w-full aspect-square" />
      </div>
      <h3 className="mt-14 text-2xl font-medium leading-8 text-center text-neutral-800 max-md:mt-10">{title}</h3>
      <p className="mt-14 text-xl leading-8 text-center text-neutral-600 max-md:mt-10">{description}</p>
    </div>
  );
};

const FeatureSection: React.FC = () => {
  const features = [
    {
      title: "Solving VC-Specific Lead Challenges",
      description: "Finding the right leads can be difficult for VCs. Growthpartner is hyper-specialized in venture capital, delivering precisely targeted leads that align with your investment goals.",
      iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e35371826d27dddc82a8addbfb64f9891944c1b8330d1ec62f9603e8e0668879?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b"
    },
    {
      title: "Fixing Inconsistent Lead Quality",
      description: "Low-quality leads waste time and resources. Our AI-driven approach ensures you receive high-quality, actionable leads, eliminating guesswork and inefficiencies.",
      iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/aeae5e3aa913e6ff4a76251b61417d12b4fd432df374513dbc724414270253ee?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b"
    },
    {
      title: "Scalable Solutions for Continuous Growth",
      description: "Growthpartner provides a scalable system that guarantees a steady stream of high-potential opportunities, no matter your growth stage.",
      iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e16487204488a966edb4542283ec03a07788c9f6ed45935758eb8339dac87ef8?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b"
    }
  ];

  return (
    <section className="flex flex-col justify-center px-8 py-16 w-full rounded-xl bg-neutral-100 min-h-[829px] max-md:px-5 max-md:max-w-full">
      <h2 className="px-96 pb-px w-full text-5xl font-medium leading-none text-center text-neutral-800 max-md:px-5 max-md:max-w-full max-md:text-4xl">
        <span className="text-neutral-800">Why </span>
        <span className="text-blue-500">GrowthPartner?</span>
      </h2>
      <div className="flex gap-2.5 justify-center items-start self-center p-2.5 mt-20 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-8 justify-center items-center px-4 py-10 min-w-[240px] max-md:max-w-full">
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;