import React from 'react';
import FeatureItem from './FeatureItem';

interface Feature {
  icon: string;
  text: string;
}

const features: Feature[] = [
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/09507fb3394e8b685ab8b2d76def9fda7c715f37e8d497f799c68c1d724f1c06?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "Dedicated Account Manager" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/09507fb3394e8b685ab8b2d76def9fda7c715f37e8d497f799c68c1d724f1c06?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "Campaign Cadence Copy Written" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/09507fb3394e8b685ab8b2d76def9fda7c715f37e8d497f799c68c1d724f1c06?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "Social Media Brand Building" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/09507fb3394e8b685ab8b2d76def9fda7c715f37e8d497f799c68c1d724f1c06?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "1-On-1 Onboarding & Support" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/09507fb3394e8b685ab8b2d76def9fda7c715f37e8d497f799c68c1d724f1c06?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "DFY Managed Campaigns" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d2aadad052d7d0d0cfa25e58c619588672bb662fbf8e03a934d1995cd6b3e49f?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "Companies List Built" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f12c670232d168cf3e321a633afc4a5b08a5cdbc8ea674cc1f113173b85d76eb?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "Weekly Reporting" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f2cdc2fc8f748053a16341ff03c1b363c0b2fea73a9cd50881116f94e1bbe458?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "Infrastructure Setup" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/828f3666d20fdfa95dead483b683f07f3cf47ea4476830dbaa5fd42a1afc9e70?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "Monthly Check-In Call" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/803d88b2dcf21c90ab3cfb7d379280329f76c18dd9ef7dc20b2e8ce930a1f6eb?apiKey=9d9796e9e6b74b058a205587047b814b&", text: "AI-Powered Personalization" }
];

const IncludedFeatures: React.FC = () => {
  return (
    <section className="flex flex-col mt-32 mb-32 items-center justify-center px-4 sm:px-8 md:px-16 lg:px-56 w-full">
      <div className="flex flex-col items-center justify-center w-full max-w-[913px]">
        <h2 className="text-4xl sm:text-5xl font-medium text-center leading-tight sm:leading-[58px] text-neutral-800 w-full max-w-[598px] mb-8">
          <span className="text-neutral-800">See What's Included For</span>
          <br />
          <span className="text-blue-500">You</span>
        </h2>
        <div className="flex flex-wrap gap-6 content-start items-start self-stretch p-4 sm:p-8 lg:p-12 leading-8 rounded-xl bg-neutral-100 w-full text-neutral-600">
          <div className="flex flex-wrap gap-10 items-start w-full">
            {features.map((feature, index) => (
              <FeatureItem key={index} icon={feature.icon} text={feature.text} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default IncludedFeatures;
