import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="flex overflow-hidden flex-col w-full grow-0 max-md:max-w-full">
      <div className="flex flex-col justify-center items-center px-20 py-14 w-full bg-neutral-800 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-start w-full max-w-[1345px] max-md:max-w-full">
          <div className="flex flex-col w-80 max-w-full h-[81px]">
            <div className="flex flex-col justify-center w-full min-h-[85px]">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/48786b5eeb48734f1c69eeef4de029685075731fb0256bbad0924fa57a1ed95f?placeholderIfAbsent=true&apiKey=9d9796e9e6b74b058a205587047b814b" alt="Company Logo" className="object-contain w-full aspect-[5.81]" />
            </div>
          </div>
          <a href="mailto:kamal@growthpartnerhq.com" className="mt-8 text-base leading-loose text-white underline">
            kamal@growthpartnerhqcom
          </a>
          <div className="flex shrink-0 self-stretch mt-8 h-px bg-neutral-600 max-md:mt-8 max-md:max-w-full" />
          <p className="mt-8 text-base leading-6 text-white max-md:mt-10">
            © 2024 GrowthPartnerHQ. All right reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;