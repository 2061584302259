import React from 'react';

interface ButtonProps {
  text: string;
  icon?: string;
  link?: string;
}

const Button: React.FC<ButtonProps> = ({ text, icon, link }) => {
  const Component = link ? 'a' : 'button';
  return (
    <Component
      href={link}
      target={link ? "_blank" : undefined} // Add target for links
      rel={link ? "noopener noreferrer" : undefined} // Add rel for security
      className="flex w-fit overflow-hidden gap-2.5 items-start my-4 px-8 py-3.5 text-xl leading-none text-white shadow-sm bg-neutral-800 rounded-[50px] max-md:px-5"
    >
      <span className="overflow-hidden underline">{text}</span>
      {icon && <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 w-6 aspect-square" />}
    </Component>
  );
};

export default Button;
